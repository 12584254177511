import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Looking In The Past For Ideas`}</h1>
    <p>{`Did you know Student Forum used to work like the US Senate? Each TA would choose 2 members to represent them on the Forum and they would meet every Wednesday morning? Did you know that we used to do Back To School dances, like Portola? Did you know there used to be a Smoothie Club that used to make smoothies live on campus and sell it to you? `}</p>
    <p>{`Our school's past is as great as the future. We have so many teachers, staff, and administrators that have seen what Irvine High has to offer. We should look into the past for insane, out-of-the-box, different ideas. Over time, things change because change is good, but old event ideas are new to us! I think that we should ask staff members what their favorite activities were over the years and implement them!`}</p>
    <p>{`We can create ideas all we want, but there's nothing better than reusing a perfectly good idea we haven't used in years! In order to make each year better than the rest, we need to be quick and have fun ideas :D`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      